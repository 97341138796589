import apiClient from '@/apis/apiClient';
// import axios from 'axios';

async function uploadImage(image) {
    try {
        const formData = new FormData();
        formData.append('image', image);

        // need to check is need to remove
        // const imageData =  await axios.post(`${ process.env.VUE_APP_BASE_URL}uploadImage`, formData, {
        //     headers: {
        //         'Content-Type': 'multipart/form-data',
        //         'csrf-token': localStorage.getItem('csrf-token') || '',
        //     }
        // });
        const apiUri = '/uploadImage';

        const imageData = await apiClient.post(apiUri, formData);

        // need to remove
        console.log('imageData', imageData);

        return imageData;

    } catch (err) {
        console.log(err);
    }
}

export {
    uploadImage,
}