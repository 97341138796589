<template>
<b-container fluid>
    <notifications group="event" />

    <b-card>
        <b-row class="flex-wrap">
            <b-col cols="12">
                <div class="title"> 活動資訊 </div>
            </b-col>
            <b-col cols="6">
                <label class="label" for="selectedCategories">活動分類:</label>
                <b-form-select
                    id="selectedCategories"
                    v-model="selectedCategories"
                    :options="categories">
                </b-form-select>
            </b-col>

            <b-col cols="6">
                <label class="label" for="activityDate">活動日期:</label>
                <vue-ctk-date-time-picker
                    :no-label="true"
                    :only-date="true"
                    format="YYYY/MM/DD"
                    formatted="YYYY/MM/DD"
                    :placeholder="'活動日期'"
                    v-model="activityDate" />
            </b-col>

            <b-col cols="6">
                <label class="label" for="cover">活動封面圖:</label>
                <b-form-file
                    @change="uploadCoverImage($event)"
                    v-model="coverFile"
                    ref="eventCoverImage"
                    placeholder="Choose a file or drop it here..."
                    drop-placeholder="Drop file here..."
                    accept="image/jpeg, image/png, image/jpg"
                    >
                </b-form-file>
                <div v-if="coverImage" class="cover-image mt-3">
                    <img class="w-100 d-inline-block"  :src="coverImage" alt="活動封面照">
                    <div class="cover-image-title my-1">((封面照片預覽))</div>
                </div>
            </b-col>
        </b-row>

        <hr class="my-4">

        <b-row class="flex-wrap">
            <!-- 中文內容 -->
            <b-col cols="6" class="content-wrapper content-lang-zh">
                <div class="title title-lang"> 中文 </div>

                <div class="content-item">
                    <label class="label" for="titleZh">標題(H1):</label>
                    <b-form-input id="titleZh" v-model="zh.title" placeholder="請輸入中文標題(H1)"></b-form-input>
                </div>

                <div class="content-item">
                    <label class="label" for="input-small">描述:</label>
                    <b-form-textarea
                        id="descriptionZh"
                        v-model="zh.description"
                        placeholder="Enter something..."
                        rows="3"
                        max-rows="4"
                    ></b-form-textarea>
                </div>

                <div class="content-item">
                    <div class="label">詳細頁內容:</div>
                    <quillEditor
                        v-model="zh.content"
                        ref="editorZh"
                        :options="editorOption"
                        @focus="onEditorFocus('editorZh')"
                        >
                    </quillEditor>
                    <input class="d-none" type="file" id="getFile" @change="uploadQuillImage($event)" />
                </div>
            </b-col>

            <!-- 英文內容 -->
            <b-col cols="6" class="content-wrapper content-lang-en">
                <div class="title title-lang"> 英文 </div>
                <div class="content-item">
                    <label class="label" for="titleEn">標題(H1):</label>
                    <b-form-input id="titleEn" v-model="en.title" placeholder="請輸入英文標題(H1)"></b-form-input>
                </div>

                <div class="content-item">
                    <label class="label" for="input-small">描述:</label>
                    <b-form-textarea
                        id="descriptionEn"
                        v-model="en.description"
                        placeholder="Enter something..."
                        rows="3"
                        max-rows="4"
                    ></b-form-textarea>
                </div>

                <div class="content-item">
                    <div class="label">詳細頁內容:</div>
                    <quillEditor
                        v-model="en.content"
                        ref="editorEn"
                        :options="editorOption"
                        @focus="onEditorFocus('editorEn')"
                        >
                    </quillEditor>
                </div>
            </b-col>
        </b-row>

        <hr class="my-4">
        <router-link to="/admin" class="btn btn-outline-secondary" tag="button">取消</router-link>
        <button @click="handleEvent" class="btn btn-primary ml-2">送出</button>

    </b-card>
</b-container>
</template>

<script>
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
import imageCompression from 'browser-image-compression';

import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';

import { quillEditor } from 'vue-quill-editor';
import { uploadImage } from '@/apis/apiImage';

import { postEvent, putEvent, getEvent} from '@/apis/apiEvents';
import { getCategories } from '@/apis/apiEventCategories';

export default {
    name: 'adminEditor',

    components: {
        quillEditor,
        VueCtkDateTimePicker,
    },

    data() {
        return {
            id: '',

            selectedCategories: '',
            activityDate: '',
            coverFile: null,
            focusQuill: 'editorZh',

            zh: {
                title: '',
                description: '',
                content: '',
            },

            en: {
                title: '',
                description: '',
                content: '',
            },

            coverImage: '',
            
            categories: [],

            editorOption: {
                modules: {
                    toolbar: {
                        "container":[ 
                            // toggled buttons
                            ['bold', 'italic', 'underline', 'strike'],

                            ['blockquote', 'code-block'],

                            // custom text header
                            [{ 'header': [2,3,4, false]}],

                            [{ 'list': 'ordered'}, { 'list': 'bullet' }],

                            // outdent/indent
                            [{ 'indent': '-1'}, { 'indent': '+1' }],              

                            // dropdown with defaults from theme
                            [{ 'color': [] }, { 'background': [] }],

                            [{ 'align': [] }],

                            // remove formatting button
                            ['clean'],                                             
                
                            // link and image, video
                            [ 'link', 'image', 'video' ],                         
                        ],
                        handlers: {
                            'image': function() {
                                document.getElementById('getFile').click();
                            }
                        }
                    }
                },
                theme: "snow",
                placeholder: '請輸入詳細頁內容',
            },
        }
    },

    created() {
        this.initialView();
    },

    methods: {
        async initialView() {
            const eventTitle = this.$route.query.eventTitle ? this.$route.query.eventTitle : '';

            const categories = await getCategories();

            this.categories = categories.map(item => {
                const obj = {}
                obj.value = item.value;
                obj.text = item.text.zh;
                return obj;
            });

            this.selectedCategories = categories[0] ? categories[0].value : '';

            if (eventTitle) {
                const data = await getEvent(eventTitle);

                this.activityDate = data.activityDate;
                this.zh = Object.assign({}, data.zh);
                this.en = Object.assign({}, data.en);
                this.id = data._id;

                this.coverImage = data.cover;

                const category = data.category._id;
                const categoryItem = categories.find(item => item.value === category);
                
                if (categoryItem) {
                    this.selectedCategories = categoryItem.value;
                }
            }
        },

        onEditorFocus(refQuill) {
            this.focusQuill = refQuill;
        },

        async uploadQuillImage(e){
            const imageFile = e.target.files[0];

            const compressOptions = {
                maxSizeMB: 0.3,
                maxWidthOrHeight: 960,
                useWebWorker: true
            }
            try {
                const compressFile = await  imageCompression(imageFile, compressOptions);
                const imageData  = await uploadImage(compressFile);

                if (imageData && imageData.status === 200) {
                    const imageFilePath = imageData.data.imageFilePath;
                    const { focusQuill } = this;

                    if (focusQuill === 'editorZh') {
                        const range = this.$refs.editorZh.quill.getSelection();
                        this.$refs.editorZh.quill.insertEmbed(range.index , 'image', imageFilePath)
                    } else {
                        const range = this.$refs.editorEn.quill.getSelection();
                        this.$refs.editorEn.quill.insertEmbed(range.index , 'image', imageFilePath)
                    }

                    document.getElementById('getFile').value = '';
                }
            } catch (error) {
                console.log(error);
            } 
        },

        async handleEvent() {
            const eventTitle = this.$route.query.eventTitle ? this.$route.query.eventTitle : '';

            const eventObj = {};
            eventObj.activityDate = this.activityDate;
            eventObj.cover = this.coverImage;
            eventObj.zh = {...this.zh};
            eventObj.en = {...this.en};
            
            const categoryItem = this.categories.find(item => item.value ===  this.selectedCategories);
            eventObj.category = categoryItem.value;

            if (eventTitle) {
                eventObj.id = this.id;
                await putEvent(eventObj)
            } else {
                await postEvent(eventObj);
            }

            this.$router.push({
                path: '/admin'
            })
        },

        async uploadCoverImage(e) {
            const imageFile = e.target.files[0];

            const compressOptions = {
                maxSizeMB: 0.3,
                maxWidthOrHeight: 960,
                useWebWorker: true
            }

            try {
                const compressFile = await  imageCompression(imageFile, compressOptions);
                const imageData = await uploadImage(compressFile);
                
                if (imageData && imageData.status === 200) {
                    setTimeout(function() {
                        this.coverImage = imageData.data.imageFilePath;
                    }.bind(this), 700);
                    e.target.value = '';
                    return;
                }

                this.$notify({
                    group: 'category',
                    type: 'warn',
                    title: `${imageData.message}`,
                    position: 'top center',
                });
            } catch (error) {
                console.log(error);
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.preview-image{
    img {
        max-width: 100%;
        width: 100%;
        height: 255px;
        border: 1px solid #eaeaea;
        object-fit: cover;
    }
}

.quill-editor::v-deep  .ql-container{
    height: 600px;
    overflow-y: auto;
}

.label{
    display: inline-block;
    margin-bottom: 5px;
    color: #949494;
    margin-top: 20px;
    font-size: 14px;
    font-weight: 500;
}

.title {
    font-size: 26px;
    font-weight: 300;
}

.content-wrapper{
    padding-top: 0px;
    padding-bottom: 15px;
}

.content-lang-zh {
    position: relative;
    &:after {
        content: "";
        display: inline-block;
        position: absolute;
        width: 1px;
        right: 0;
        top: 70px;
        bottom: 17px;
        background: #e8e8e8;
    }
}

.cover-image-title{
    font-size: 14px;
    color:#949494;
    text-align: center;
}

.cover-image{
    max-width: 180px;
}

.quill-editor::v-deep .ql-snow{
    font-size: 18px;
    color: #666;
    h2, h3, h4 {
        color: #222222;
        font-weight: 400;
    }
    p {
        color: #777;
        margin-bottom: 0;
    }

    p > a {
        font-size: 16px;
    }

    iframe.ql-video {
        width: 100%;
        max-width: 100%;
        height: 420px;
    }

    img {
        max-width: 100%;
        display: inline-block;
    }
}
</style>