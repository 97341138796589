import apiClient from '@/apis/apiClient';

// category === '', will get all categories data
async function getEvents({currentPage, perPageItems, category = ''}) {
    try {
        const apiUri = '/events';
        const requestBody = {
            currentPage,
            perPageItems,
            category,
        }
        const { data }  = await apiClient.post(apiUri, requestBody);
        return data;
    } catch (err) {
        console.log(err);
    }
}

async function getEvent(eventTitle) {
    try {
        const { data } = await apiClient.post('/getEvent', {
            eventTitle,
        });
        return data;
    } catch (err) {
        console.log(err);
    }
}

async function postEvent(eventObj) {
    try {
        await apiClient.post('/createEvent', eventObj);
    } catch (err) {
        console.log(err);
    }
}

async function putEvent(eventObj) {
    try {
        await apiClient.put('/putEvent', eventObj);
    } catch (err) {
        console.log(err);
    }
}

async function deleteEvent(id) {
    try {
        const result = await apiClient.post('/deleteEvent', {
            id,
        });

        return result;
    } catch (err) {
        console.log(err);
    }
}

export { 
    getEvents,
    getEvent,
    postEvent,
    putEvent,
    deleteEvent,
};