import apiClient from '@/apis/apiClient';

async function getCategories() {
    try {
        const { data } = await apiClient.get('/categories');

        const categories = data.map(item => {
            return {
                text: item.category,
                value: item._id
            }
        });

        return categories;
    }  catch (err) {
        console.log(err);
    }
}


async function deleteCategory(id) {
    try {
        const result = await apiClient.post('/deleteCategory', { id: id});
        return result;
    } catch (err) {
        console.log('err', err);
    }
}

async function createCategory(category) {
    try {
        await apiClient.post('/createCategory', category);
    }  catch (err) {
        console.log(err);
    }
}

async function putCategory({id, category}) {
    try {
        await apiClient.put('/putCategory', {
            id,
            category,
        });
    } catch (err) {
        console.log(err);
    }
}

export {
    getCategories,
    createCategory,
    deleteCategory,
    putCategory,
};